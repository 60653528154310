<template>
  <el-dialog
    title="  "
    :visible.sync="visible"
    width="400px"
    custom-class="user-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="user-head" v-if="info.realName">{{info.realName.substr(0, 1)}}</div>
      <div class="user-name text-center">{{info.realName}}</div>
      <div class="flex flex-center">
        <div>
          <span class="gray">账号状态：</span>
          <span class="point" :style="{'background': statusColor[info.status]}"></span>
          <span class="black">{{statusMap[info.status]}}</span>
        </div>
        <div class="m-l-16">
          <span class="gray">创建时间</span>：
          <span class="black">{{info.createTime}}</span>
        </div>
      </div>
      <div class="user-info">
        <div>
          <span class="gray">用户姓名</span>：
          <span class="black">{{info.realName}}</span>
        </div>
        <div>
          <span class="gray">登录账号</span>：
          <span class="black">{{info.username}}</span>
        </div>
        <div>
          <span class="gray">手机号码</span>：
          <span class="black">{{info.phone}}</span>
        </div>
        <div>
          <span class="gray">职位</span>：
          <span class="black">{{info.position}}</span>
        </div>
        <div>
          <span class="gray">角色</span>：
          <span class="black">{{info.roleName}}</span>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button type="primary" size="small" @click="hideDialog">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    statusMap: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      statusColor: {
        1: '#67C23A',
        2: '#BF6868'
      }
    }
  },
  methods: {
    hideDialog() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .user-dialog{
  .el-dialog__header{
    border: none;
  }
}
.user-head{
  width: 88px;
  height: 88px;
  line-height: 88px;
  background: #DFE8F9;
  border-radius: 50%;
  margin: 0 auto 8px;
  text-align: center;
  color: #487AD5;
  font-size: 24px;
  white-space: nowrap;
}
.user-name{
  font-size: 20px;
  margin-bottom: 20px;
}
.point{
  margin-right: 4px;
}
.black{
  color: #333;
}
.gray{
  color: #666;
}
.user-info{
  padding-left: 100px;
  border-top: 1px solid #eee;
  margin: 20px -20px 0;
  & > div{
    margin-top: 20px;
    display: flex;
  }
  .gray{
    width: 60px;
    text-align: justify;
    text-align-last: justify;
  }
}
</style>