import { render, staticRenderFns } from "./change-pwd-dialog.vue?vue&type=template&id=45e9e7d1&scoped=true"
import script from "./change-pwd-dialog.vue?vue&type=script&lang=js"
export * from "./change-pwd-dialog.vue?vue&type=script&lang=js"
import style0 from "./change-pwd-dialog.vue?vue&type=style&index=0&id=45e9e7d1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e9e7d1",
  null
  
)

export default component.exports