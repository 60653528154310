<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          登录账号
        </span>
        <el-input class="flex-1" v-model="form.username" size="small" :disabled="!!info.id" maxlength="32" placeholder="用以登录系统，请勿重复"></el-input>
      </div>
      <div class="form-item flex flex-center" v-if="!info.id">
        <span class="form-label">
          <span class="must">*</span>
          初始密码
        </span>
        <el-input class="flex-1" v-model="form.pwd" size="small" disabled></el-input>
        <span class="input-inner-tips">关闭弹窗后不再显示，请牢记</span>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          用户姓名
        </span>
        <el-input class="flex-1" v-model="form.realName" size="small" minlength="2" maxlength="8" show-word-limit placeholder="请输入真实姓名，方便他人识别"></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          手机号码
        </span>
        <el-input class="flex-1" v-model="form.phone" size="small" placeholder="请输入用户有效手机号码"></el-input>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          <span class="must">*</span>
          用户角色
        </span>
        <el-select
          class="flex-1" size="small"
          v-model="form.roleId"
          filterable
          remote
          :remote-method="remoteMethod"
          :loading="selectLoading">
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
            v-show="item.roleCode != adminCode"
          >
          </el-option>
        </el-select>
      </div>
      <div class="form-item flex flex-center">
        <span class="form-label">
          职位
        </span>
        <el-input class="flex-1" v-model="form.position" size="small" placeholder="请输入用户职位"></el-input>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button class="border-btn" plain size="small" @click="confirm" v-if="!info.id" :disabled="loading">
        保存并继续添加
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
      <el-button type="primary" size="small" @click="confirmWithClose" :disabled="loading">
        保存
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import {
  add_user,
  update_user,
  get_role_list
} from '@/api/user'
import { FormValid } from "@/utils/formValid";
import { uuid } from '@/utils/uuid'

const form = {
  username: '',
  pwd: '',
  realName: '',
  phone: '',
  roleId: '',
  position: ''
}
export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      roleList: [],
      role100List: [],
      selectLoading: false,
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.info.id) {
          this.form = this.$deepClone(this.info)
        } else {
          this.form.pwd = uuid(null, null, 'pwd')
        }
      } else {
        this.form = this.$deepClone(form)
      }
    }
  },
  computed: {
    ...mapState(['adminCode']),
    title() {
      return this.info.id ? '编辑用户' : '新增用户'
    },
    checkFormList() {
      let arr = [
        {
          label: '登录账号',
          value: 'username',
          methods: ['notEmpty']
        },
        {
          label: '用户姓名',
          value: 'realName',
          methods: ['notEmpty']
        },
        {
          label: '手机号码',
          value: 'phone',
          methods: ['notEmpty', 'isPhone']
        },
        {
          label: '用户角色',
          value: 'roleId',
          methods: ['notEmpty']
        }
      ]
      return arr
    }
  },
  mounted() {
    this.getRole()
  },
  methods: {
    async getRole(search) {
      const res = await get_role_list({
        search,
        size: 100,
        pageNo: 1
      })
      this.selectLoading = false;
      this.roleList = res ? res.records : []
      if (!search) {
        this.role100List = this.roleList.concat()
      }
    },
    remoteMethod(query) {
      if (query) {
        this.selectLoading = true;
        setTimeout(() => {
          this.getRole(query.toLowerCase())
        }, 200);
      } else {
        this.roleList = this.role100List.concat()
      }
    },
    hideDialog() {
      this.$emit('close')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      if (this.form.username.length < 6) {
        this.$message.warning('登录账号长度须在6-32位')
        return false
      }
      if (this.form.realName.length < 2) {
        this.$message.warning('用户姓名长度须在2-8位')
        return false
      }
      return {
        ...this.form
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      this.loading = true
      add_user(params).then(() => {
        this.loading = false
        this.$message.success('保存成功')
        this.form = this.$deepClone(form)
        this.form.pwd = uuid(null, null, 'pwd')
        this.$emit('confirm')
      }).catch(() => {
        this.loading = false
      })
    },
    confirmWithClose() {
      let params = this.checkForm()
      if (!params) return
      console.log(params)
      this.loading = true
      if (this.info.id) {
        update_user(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
          this.hideDialog()
        }).catch(() => {
          this.loading = false
        })
      } else {
        add_user(params).then(() => {
          this.loading = false
          this.$message.success('保存成功')
          this.$emit('confirm')
          this.hideDialog()
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  position: relative;
  .must{
    color: #E14B38
  }
  .form-label{
    width: 80px;
    text-align: right;
    padding-right: 20px;
  }
}
.input-inner-tips{
  position: absolute;
  right: 10px;
  color: #BCBCBC;
  font-size: 12px;
}
</style>