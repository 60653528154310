<template>
  <el-dialog
    title="重置密码"
    :visible.sync="visible"
    width="570px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="hideDialog">
    <div>
      <div class="dialog-tips">
        <div><i class="el-icon-warning-outline"></i> 重置密码后，系统不会通知用户，请将密码告知用户，确保其正常使用。</div>
        <div>（关闭弹窗后将不再显示，请牢记）</div>
      </div>
      <div class="form-item flex">
        <span class="form-label" style="line-height: 32px;">
          重置密码
        </span>
        <div class="flex-1">
          <el-input v-model="form.pwd" size="small" maxlength="15" placeholder="请输入新密码"></el-input>
          <div class="input-tips">需包含大写字母、小写字母、特殊符号、数字中的任意三项，长度为8-15位</div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button size="small" @click="hideDialog">取消</el-button>
      <el-button type="primary" size="small" @click="confirm" :disabled="loading">
        确认
        <i class="el-icon-loading" v-show="loading"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  update_pwd
} from '@/api/user'
import { FormValid } from "@/utils/formValid";

const form = {
  pwd: ''
}
export default {
  props: {
    visible: {
      type: Boolean
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$deepClone(form),
      loading: false
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.form = this.$deepClone(this.info)
      } else {
        this.form = this.$deepClone(form)
      }
    }
  },
  computed: {
    checkFormList() {
      let arr = [
        {
          label: '新密码',
          value: 'pwd',
          methods: ['notEmpty']
        }
      ]
      return arr
    }
  },
  methods: {
    hideDialog() {
      this.$emit('close')
    },
    checkForm() {
      const formValid = new FormValid();
      for (const f of this.checkFormList) {
        const { label, value, methods } = f;
        const v = this.form[value]
        formValid.add(label, v, methods)
      }
      const res = formValid.start();
      if (res !== true) {
        this.$message.warning(res)
        return false;
      }
      return {
        ...this.form,
        userId: this.info.id,
        clientType: 'DINGYI_MANAGER' //运维
      };
    },
    confirm() {
      let params = this.checkForm()
      if (!params) return
      this.loading = true
      update_pwd(params).then(() => {
        this.loading = false
        this.$message.success('重置密码成功')
        this.$emit('confirm')
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-item{
  padding-left: 7px;
  margin-bottom: 16px;
  .form-label{
    width: 110px
  }
  .input-tips{
    font-size: 12px;
    color: #cccccc;
    margin-top: 12px;
    padding: 0 15px;
  }
}

::v-deep .el-dialog__body{
  padding-top: 0;
}
</style>