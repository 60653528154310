<template>
  <div>
    <div class="page-content">
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">筛选查询</span>
      </div>
      <div>
        <div class="search-item">
          <span class="label">姓名/手机号码</span>
          <el-input v-model="searchParams.search" size="small" clearable style="width: 240px" placeholder="请输入姓名/手机号码"></el-input>
        </div>
        <div class="search-item">
          <span class="label">状态</span>
          <el-select v-model="searchParams.status" placeholder="请选择" clearable size="small" style="width: 160px">
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in statusOption"
              :key="item.k"
              :label="item.v"
              :value="item.k">
            </el-option>
          </el-select>
        </div>
        <div class="search-item">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button class="border-btn" plain size="small" @click="reset">重置</el-button>
        </div>
      </div>
      <div class="page-sub-title flex flex-center">
        <span class="flex-1">用户管理</span>
        <el-button type="primary" size="small" @click="showEditDialog()">+ 新增用户</el-button>
      </div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        header-row-class-name="table-header"
      >
        <el-table-column
          align="center"
          label="姓名"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showDetailDialog(scope.row)">{{scope.row.realName}}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          label="手机号码"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="position"
          label="职位"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="roleName"
          label="角色"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            <span class="point" :style="{'background': statusColor[scope.row.status]}"></span>
            {{statusMap[scope.row.status]}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="160"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="showEditDialog(scope.row)" :disabled="scope.row.roleCode == adminCode">编辑</el-button>
            <el-button type="text" @click="showPwdDialog(scope.row)">重置密码</el-button>
            <el-popconfirm
              confirm-button-text="确认"
              cancel-button-text="取消"
              :title="`确定${scope.row.status == 1 ? '禁用' : '启用'}该用户？`"
              :icon="scope.row.status === 1 ? 'el-icon-circle-close' : 'el-icon-circle-check'"
              :icon-color="scope.row.status === 1 ? '#bc7373' : '#83bf68'"
              @confirm="changeUserStatus(scope.row)"
            >
              <el-button slot="reference" type="text" style="margin-left: 10px;" :disabled="scope.row.roleCode == adminCode">{{scope.row.status == 1 ? '禁用' : '启用'}}</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        class="text-right m-t-16"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page="pageParams.pageNo"
        :page-size="pageParams.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems">
      </el-pagination>
    </div>

    <edit-user-dialog :visible="editVisible" :info="editData" @close="hideEditDialog" @confirm="getList()"></edit-user-dialog>

    <change-pwd-dialog :visible="pwdVisible" :info="editData" @close="hidePwdDialog" @confirm="confirmDialog"></change-pwd-dialog>

    <user-detail-dialog :visible="detailVisible" :info="editData" :statusMap="statusMap" @close="hideDetailDialog"></user-detail-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { pageMixin } from '@/mixins/pageMixin'
import { get_user_list, disable_user, enable_user } from '@/api/user'
import { get_key_value } from '@/api/common'
import editUserDialog from './component/edit-user-dialog'
import changePwdDialog from './component/change-pwd-dialog'
import userDetailDialog from './component/user-detail-dialog'

export default {
  mixins: [pageMixin],
  components: {
    editUserDialog,
    changePwdDialog,
    userDetailDialog
  },
  data() {
    return {
      statusColor: {
        1: '#67C23A',
        2: '#BF6868'
      },
      statusOption: [],
      statusMap: {},
      searchParams: {
        search: '',
        status: ''
      },
      editVisible: false,
      editData: {},
      pwdVisible: false,
      detailVisible: false
    }
  },
  computed: {
    ...mapState(['adminCode'])
  },
  mounted() {
    this.getOption()
    this.getList()
  },
  methods: {
    getOption() {
      get_key_value({
        type: 'USER_STATUS'
      }).then(res => {
        this.statusOption = res ? res.records : []
        this.statusMap = this.$arrayToMap(this.statusOption)
      })
    },
    async getList() {
      let params = {
        ...this.pageParams,
        ...this.searchParams
      }
      const res = await get_user_list(params)
      console.log(res)
      this.list = res ? res.records : []
      this.totalItems = res ? res.total : 0
    },
    showDetail(row) {
      this.$router.push({
        name: 'finance-invoice-detail',
        query: {
          invoiceRecordId: row.id,
          invoiceDetail: row
        }
      })
    },
    showEditDialog(row) {
      if (row) {
        this.editData = this.$deepClone(row)
      } else {
        this.editData = {}
      }
      this.editVisible = true
    },
    hideEditDialog() {
      this.editData = {}
      this.editVisible = false
    },
    showPwdDialog(row) {
      if (row) {
        this.editData = this.$deepClone(row)
      } else {
        this.editData = {}
      }
      this.pwdVisible = true
    },
    hidePwdDialog() {
      this.editData = {}
      this.pwdVisible = false
    },
    confirmDialog() {
      this.hidePwdDialog()
      this.getList()
    },
    showDetailDialog(row) {
      this.detailVisible = true
      this.editData = row
    },
    hideDetailDialog() {
      this.editData = {}
      this.detailVisible = false
    },
    changeUserStatus(row) {
      if (row.status == 1) {
        disable_user({
          id: row.id
        }).then(() => {
          this.$message.success('禁用成功')
          this.getList()
        })
      } else {
        enable_user({
          id: row.id
        }).then(() => {
          this.$message.success('启用成功')
          this.getList()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>